import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "../../../router/styles/drawer.css";
import "./style.css";
import { MdContacts } from "react-icons/md";
import { IoArrowRedo } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import { Link } from 'react-router-dom';
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Button, IconButton, Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from '@mui/material/CircularProgress';
import Report from "../../../Accets/reportwp.png";
import Profile from "../../../Accets/cont.png";
import Mess from "../../../Accets/mes.png";
import Chat from "../../../Accets/chat.png";
import Expo from "../../../Accets/expo.png";
import Api from "../../../Accets/api.png";
import Contact from "../../../Accets/contasss.png";
import PROFILELOGO2 from "../../../Accets/profile logo2.png";
import PARTICIPATLOGO2 from "../../../Accets/paticipat logo2.png";
import robotLogo from "../../../Accets/robot image1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cardlogo from "../../../Accets/card logo1.png";
import NSWHATLOGO3 from "../../../Accets/sns logo1.png";
import CARDLOGO5 from "../../../Accets/card logo5.png";
import CARDLOGO6 from "../../../Accets/Card logo 6.png";
import { LuPlusSquare } from "react-icons/lu";
import { TfiMenuAlt } from "react-icons/tfi";
import BULKMESSAGELOGO from "../../../Accets/bulk message logo1.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BULKLOGO2 from "../../../Accets/bulk logo2.png";
import BULKLOGO3 from "../../../Accets/sns logo1.png";
import LOGO3 from "../../../Accets/card logo5 2.png";
import LOGO7 from "../../../Accets/logo7.png";
import CONTACTIMAGE1 from "../../../Accets/Contact image1.png";
import { FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { Card, Divider, Grid } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { FaWhatsapp } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "transparent" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#9747FF"
        : "linear-gradient(89.97deg, #9747FF 1.43%, rgba(56, 182, 255, 0.33) 134.69%)",
  },
}));
const drawerWidth = 280;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "60px 150px 0px 10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PermanentDrawerLeft(props) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [credit, setCredit] = useState(5); // Initial credit
  const [inputValue, setInputValue] = useState(''); // To store input
  const [error, setError] = useState(''); // To store error messages
  const [loading, setLoading] = useState(false); // To show loading state

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the input value as the user types
  };

  const handleCredit = (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page
    const numbers = inputValue.split(',').filter((num) => num.trim() !== '');

    if (numbers.length > credit) {
      setError('Credit is not sufficient'); // Show error if credit is insufficient
    } else {
      setLoading(true); // Show loading
      setError(''); // Clear any previous errors

      // Wait for 10 seconds, then execute the credit deduction
      setTimeout(() => {
        setCredit(credit - numbers.length); // Decrease credit based on the input after submission
        setLoading(false); // Remove loading state after execution
      }, 10000); // 10000 milliseconds = 10 seconds
    }
  };


  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          zIndex: "10000",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginLeft: "109px",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Typography
          style={{
            margin: "20px 0px 0px",
            textAlign: "center",
            fontWeight: 500,
            fontSize: "22px",
            color: "green",
          }}
        >
          <WhatsAppIcon sx={{ margin: "20px 10px -5px", fontSize: "30px" }} />WhatsApp
        </Typography>
        <Typography
          style={{
            margin: "40px 0px 0px 15px",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          Features
        </Typography>

        <List className="drawer2-animation">
          {/* {sideBar( open)} */}
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              // aria-label="Vertical tabs example"
              sx={{ width: "300px" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 0 ? "#dcdcdc" : "transparent",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 0 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                icon={
                  <img
                    src={Profile}
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label="Own Number"
                style={{ color: "#242424" }}
                {...a11yProps(0)}
              ></Tab>

              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 1 ? "#dcdcdc" : "transparent",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 0 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                icon={
                  <img
                    src={Report}
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                    }}
                  />
                }
                label="Virtual WhatsApp"
                style={{ color: "#242424" }}
                {...a11yProps(0)}
              ></Tab>
            </Tabs>
          </Box>
        </List>
      </Drawer>

      <Box style={{ backgroundColor: "white" }}>
        <TabPanel value={value} index={0}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "100px 20px 50px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Please scan this for connecting to your Whatsapp
                </Typography>
              </Grid>



              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Grid
                  style={{
                    backgroundColor: "white",
                    width: "400px",
                    padding: "40px",
                    borderRadius: "20px"
                  }}
                >
                  <div className="qr-animation">
                    <img src="https://hexdocs.pm/qr_code/docs/qrcode.svg" alt="QR Code" style={{ width: "100%" }}></img>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "50px 20px 20px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Welcome to Own WhatsApp Speed Message Sender
                </Typography>
              </Grid>


              <h4 style={{margin: " 0 20px -10px 230px", backgroundImage: "linear-gradient(90deg, #9bd0ffc4 , #ffffffb6 )", padding: "10px 20px", width: "fit-content", borderRadius: "10px"}}>Available Credit: {credit}</h4>
              {loading && <h4 style={{margin: " 20px 20px -10px 230px", backgroundImage: "linear-gradient(90deg, #9bd0ffc4 , #ffffffb6 )", padding: "10px 20px", width: "fit-content", borderRadius: "10px"}}><CircularProgress style={{width: "25px", height: "25px", margin: "0 10px -6px 0"}} /> Processing... Please wait</h4>}
              <Grid
                className="box-animation"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Phone Number or Upload .xlsx file
                    </Typography>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "1px solid white",
                        borderRadius: "30px",
                        height: "40px",
                        fontSize: "18px",
                        margin: "10px 0px",
                        padding: "0px 10px",
                        color: "white",
                        outline: "none",
                      }}
                      value={inputValue} // Use inputValue state
                      placeholder="Enter Number (comma separated)"
                      onChange={handleInputChange} // Call handleInputChange on input
                    />
                    {error && <p style={{ color: 'red', margin: "0" }}>{error}</p>}
                    <input type="file" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Message
                    </Typography>
                    <textarea style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "100px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "10px 10px", color: "white", outline: "none" }}></textarea>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Attach
                    </Typography>
                    <select style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }}>
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <input type="file" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }} onClick={handleCredit}>Send Message</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel style={{ backgroundColor: "black" }} value={value} index={2}>
          <Grid
            style={{ backgroundColor: "#000", height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "50px 20px 20px 20px ",
                }}
              >
                <Typography
                  style={{ color: "#fff", fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Welcome to Own WhatsApp Speed Message Sender
                </Typography>
              </Grid>



              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <iframe src="http://35.200.161.163:7001/rest/api/singleWP" style={{ height: "80vh", width: "100vw" }}></iframe>
                {/* <Card className="card-two" style={{height: "auto", backgroundImage: "linear-gradient(135deg, #0C487B, #6606AD)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Phone Number or Upload .xlsx file
                    </Typography>
                    <input type="text" style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <input type="file" style={{width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Message
                    </Typography>
                    <textarea style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "100px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "10px 10px", color: "white", outline: "none"}}></textarea>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Attach
                    </Typography>
                    <select style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}}>
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <input type="file" style={{width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <button style={{maxWidth: "200px", marginTop: "20px"}}>Send Message</button>
                    </div>
                  </Grid>
                </Card> */}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Box>
  );
}

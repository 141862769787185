import React from "react";
import "../styles/dashboard.css";
import Typography from "@mui/material/Typography";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Card, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "transparent" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundImage:
      theme.palette.mode === "light"
        ? "linear-gradient(89.97deg, white 0%, #ADD8E6 100%)"
        : "linear-gradient(89.97deg, #9747FF 1.43%, rgba(56, 182, 255, 0.33) 134.69%)",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const rows = [
  { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
  { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
  { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
  { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
  { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
];
const Dashboard = () => {
  const [account, setAccount] = React.useState('');

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  return (
    <Grid style={{ backgroundColor: "white", height: "100vh", padding: "0px 5vw" }}>
      <Grid style={{ marginTop: "4%" }}>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
            padding: "50px 20px 7px 20px ",
          }}
        >
          <Typography
            style={{ color: "black", fontSize: "28px", fontWeight: 700 }}
          >
            Dashboard
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Account</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={account}
              label="Account"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Own WhatsApp Number</MenuItem>
              <MenuItem value={20}>Secondary WP Number</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Card className="card-one">
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid style={{ margin: "10px" }}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    fontSize: "18px",
                  }}
                >
                  Message by Month
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    fontSize: "11px",
                  }}
                >
                  Limit message by month
                </Typography>
              </Grid>
              <Grid style={{ margin: "10px" }}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fontSize: "23px",
                  }}
                >
                  0/5
                </Typography>
              </Grid>
            </Grid>
            <BorderLinearProgress
              variant="determinate"
              value={70}
              style={{ margin: "10px", backgroundColor: "transparent", border: "1px solid white", borderRadius: "10px" }}
            />
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid style={{ margin: "10px" }}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    fontSize: "15px",
                  }}
                >
                  Percent
                </Typography>
              </Grid>
              <Grid style={{ margin: "10px" }}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    fontSize: "17px",
                  }}
                >
                  0%
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card className="card-two">
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  margin: "20px 10px 10px 20px",
                  gap: "40px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fontSize: "24px",
                  }}
                >
                  Total Credit
                </Typography>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fontSize: "34px",
                  }}
                >
                  500{" "}
                  <span
                    style={{
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      fontSize: "14px",
                    }}
                  >
                    Credits
                  </span>
                </Typography>
              </Grid>
              <Grid style={{ margin: "20px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
                  <g opacity="0.7">
                    <rect width="66" height="66" fill="" />
                    <path d="M48.4862 24.918L36.7562 34.3622C34.5363 36.1026 31.4245 36.1026 29.2047 34.3622L17.374 24.918" stroke="black" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9416 9.625H45.8684C49.6067 9.66693 53.1648 11.2473 55.714 13.9981C58.2632 16.7488 59.581 20.4298 59.3604 24.1838V42.1355C59.581 45.8895 58.2632 49.5705 55.714 52.3213C53.1648 55.072 49.6067 56.6524 45.8684 56.6943H19.9416C11.9119 56.6943 6.5 50.1618 6.5 42.1355V24.1838C6.5 16.1575 11.9119 9.625 19.9416 9.625Z" stroke="black" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </svg>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid className="second-card">
          <Typography
            style={{
              fontWeight: 700,
              fontFamily: "Poppins",
              fontSize: "25px",
              margin: "10px",
            }}
          >
            Bulk Messaging
          </Typography>
          <Divider style={{ background: "#888", margin: "10px" }}></Divider>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "3%",
              margin: "15px",
            }}
          >
            <Grid className="second-card-sub-one">
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "20px 10px 10px 20px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "#38B6FF",
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "34px",
                    }}
                  >
                    0{" "}
                    <span
                      style={{
                        color: "#38B6FF",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      Messages
                    </span>
                  </Typography>
                </Grid>
                <Grid sx={{ margin: "20px", color: "#42AAFF" }}>
                  <svg
                    width="61"
                    height="31"
                    viewBox="0 0 61 31"
                    fill="#42AAFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.48 7.44C17.2835 7.78534 17.2311 8.19419 17.3341 8.57793C17.437 8.96167 17.6871 9.28938 18.03 9.49L38.72 21.13C38.9516 21.2673 39.2158 21.3397 39.485 21.3397C39.7542 21.3397 40.0184 21.2673 40.25 21.13L59.36 9.22V28.62C59.36 29.1504 59.1493 29.6591 58.7742 30.0342C58.3991 30.4093 57.8904 30.62 57.36 30.62H1.5C1.10218 30.62 0.720644 30.462 0.43934 30.1807C0.158035 29.8994 0 29.5178 0 29.12C0 28.7222 0.158035 28.3406 0.43934 28.0593C0.720644 27.778 1.10218 27.62 1.5 27.62H15.58V23.88H4.65C4.25217 23.88 3.87064 23.722 3.58934 23.4407C3.30804 23.1594 3.15 22.7778 3.15 22.38C3.15 21.9822 3.30804 21.6006 3.58934 21.3193C3.87064 21.038 4.25217 20.88 4.65 20.88H15.58V17H8.65C8.25218 17 7.87064 16.842 7.58934 16.5607C7.30803 16.2794 7.15 15.8978 7.15 15.5C7.15 15.1022 7.30803 14.7206 7.58934 14.4393C7.87064 14.158 8.25218 14 8.65 14H15.58V2C15.58 1.46957 15.7907 0.96086 16.1658 0.585787C16.5409 0.210714 17.0496 0 17.58 0H58.03C58.5604 0 59.0691 0.210714 59.4442 0.585787C59.8193 0.96086 60.03 1.46957 60.03 2V5.25L39.42 18.08L19.52 6.87C19.1738 6.67567 18.7646 6.62655 18.3822 6.7334C17.9998 6.84025 17.6754 7.09434 17.48 7.44Z"
                      fill="#42AAFF"
                      fill-opacity="0.7"
                    />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second-card-sub-two">
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "20px 10px 10px 20px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                  >
                    Send
                  </Typography>
                  <Typography
                    style={{
                      color: "#05E800",
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "34px",
                    }}
                  >
                    0{" "}
                    <span
                      style={{
                        color: "#05E800",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      Messages
                    </span>
                  </Typography>
                </Grid>
                <Grid style={{ margin: "20px" }}>
                  <svg
                    width="60"
                    height="48"
                    viewBox="0 0 60 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M54 24.06V8.23C53.9981 6.04784 53.1305 3.95558 51.5874 2.41256C50.0444 0.869536 47.9522 0.00185367 45.77 0H8.23C6.04784 0.00185367 3.95558 0.869536 2.41256 2.41256C0.869536 3.95558 0.00185367 6.04784 0 8.23V29.77C0.00185367 31.9522 0.869536 34.0444 2.41256 35.5874C3.95558 37.1305 6.04784 37.9981 8.23 38H34.36C34.8497 40.0715 35.8407 41.9909 37.246 43.5897C38.6513 45.1884 40.4278 46.4174 42.4194 47.1686C44.411 47.9199 46.5567 48.1704 48.6677 47.8981C50.7788 47.6258 52.7907 46.8391 54.5264 45.6071C56.2622 44.375 57.6688 42.7355 58.6224 40.8325C59.5761 38.9295 60.0476 36.8214 59.9956 34.6935C59.9436 32.5655 59.3697 30.483 58.3243 28.6288C57.2788 26.7747 55.7939 25.2058 54 24.06ZM32.19 19.64C30.735 20.8529 28.9011 21.5181 27.0068 21.5199C25.1125 21.5217 23.2774 20.8601 21.82 19.65L4.63 34.86C4.08185 34.4685 3.5992 33.9926 3.2 33.45L20.29 18.35L3.52 4.15C3.97237 3.63747 4.5021 3.19884 5.09 2.85L23.01 18.04C24.1218 18.9911 25.5369 19.5138 27 19.5138C28.4631 19.5138 29.8782 18.9911 30.99 18.04L48.91 2.85C49.4979 3.19884 50.0276 3.63747 50.48 4.15L33.74 18.33L40.25 23.89C39.6609 24.2465 39.1023 24.6512 38.58 25.1L32.19 19.64ZM47 46C44.8244 46 42.6977 45.3549 40.8887 44.1462C39.0798 42.9375 37.6699 41.2195 36.8373 39.2095C36.0048 37.1995 35.7869 34.9878 36.2114 32.854C36.6358 30.7202 37.6834 28.7602 39.2218 27.2218C40.7602 25.6834 42.7202 24.6358 44.854 24.2114C46.9878 23.7869 49.1995 24.0048 51.2095 24.8373C53.2195 25.6699 54.9375 27.0798 56.1462 28.8887C57.3549 30.6977 58 32.8244 58 35C57.9981 37.9168 56.8386 40.7136 54.7761 42.7761C52.7136 44.8386 49.9168 45.9981 47 46Z"
                      fill="#19B300"
                      fill-opacity="0.7"
                    />
                    <path
                      d="M49.8701 29.38L45.0501 34.2L44.1301 33.28C43.5036 32.6784 42.6687 32.3424 41.8001 32.3424C40.9315 32.3424 40.0966 32.6784 39.4701 33.28C38.8553 33.8996 38.5103 34.7371 38.5103 35.61C38.5103 36.4829 38.8553 37.3204 39.4701 37.94L41.7501 40.22C42.63 41.0902 43.8175 41.5783 45.0551 41.5783C46.2926 41.5783 47.4802 41.0902 48.3601 40.22L54.5301 34.04C55.1449 33.4204 55.4899 32.5829 55.4899 31.71C55.4899 30.8371 55.1449 29.9996 54.5301 29.38C53.9024 28.7807 53.0679 28.4463 52.2001 28.4463C51.3322 28.4463 50.4978 28.7807 49.8701 29.38Z"
                      fill="white"
                      fill-opacity="0.7"
                    />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second-card-sub-three">
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "20px 10px 10px 20px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "20px",
                    }}
                  >
                    Failed
                  </Typography>
                  <Typography
                    style={{
                      color: "#E51B26",
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      fontSize: "34px",
                    }}
                  >
                    0{" "}
                    <span
                      style={{
                        color: "#E51B26",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      Messages
                    </span>
                  </Typography>
                </Grid>
                <Grid style={{ margin: "20px" }}>
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M48.9591 16.5481L28.2566 31.1142C26.0026 32.7018 22.9974 32.7018 20.7434 31.1142L0.0490479 16.5514C0.0163812 16.7899 0.00817464 17.0324 0.00817464 17.2782C7.97544e-06 23.5339 0 35.0799 0 41.3389C0 42.0886 0.122452 42.8113 0.359285 43.4851L15.5657 32.7884C16.3007 32.2698 17.3215 32.447 17.836 33.1844C18.3586 33.9211 18.179 34.9411 17.444 35.4597L2.17236 46.2046C3.14419 47.0735 4.37737 47.6599 5.75754 47.8256C5.75754 47.8256 5.75755 47.8265 5.76571 47.8265C18.3097 49.2711 30.7965 49.2711 43.2425 47.8248L43.2506 47.824C44.6226 47.6574 45.8558 47.0719 46.8276 46.2037L31.556 35.4597C30.821 34.9411 30.6414 33.9211 31.164 33.1844C31.6785 32.447 32.6993 32.2698 33.4343 32.7884L48.6325 43.4834C48.8694 42.8105 48.9918 42.0902 48.9918 41.3429C49 35.0873 49 23.5404 49 17.2815C49 17.034 48.9836 16.789 48.9591 16.5481ZM13.1401 10.1095C13.8016 4.42143 18.6363 0 24.5 0C30.3637 0 35.1984 4.42225 35.8599 10.1112C38.318 10.2835 40.7843 10.5113 43.2343 10.7939C43.2425 10.7939 43.2425 10.7947 43.2425 10.7947C45.1045 11.0177 46.697 12.0042 47.7341 13.4162L26.3784 28.4429C25.2514 29.2367 23.7486 29.2367 22.6216 28.4429L1.26587 13.4178C2.30304 12.0058 3.89553 11.0201 5.74937 10.7963C5.75753 10.7963 5.75754 10.7955 5.75754 10.7955C8.22387 10.5097 10.682 10.281 13.1401 10.1095ZM24.5 3.26667C29.008 3.26667 32.6667 6.92615 32.6667 11.4333C32.6667 15.9405 29.008 19.6 24.5 19.6C19.992 19.6 16.3333 15.9405 16.3333 11.4333C16.3333 6.92615 19.992 3.26667 24.5 3.26667ZM24.5 9.1238L22.3849 7.0119C21.7479 6.37408 20.7188 6.37408 20.0818 7.0119C19.4448 7.6489 19.4448 8.68443 20.0818 9.32143L22.1889 11.4333L20.0818 13.5452C19.4448 14.1822 19.4448 15.2178 20.0818 15.8548C20.7188 16.4926 21.7479 16.4926 22.3849 15.8548L24.5 13.7429L26.6151 15.8548C27.2521 16.4926 28.2812 16.4926 28.9182 15.8548C29.5552 15.2178 29.5552 14.1822 28.9182 13.5452L26.8111 11.4333L28.9182 9.32143C29.5552 8.68443 29.5552 7.6489 28.9182 7.0119C28.2812 6.37408 27.2521 6.37408 26.6151 7.0119L24.5 9.1238Z"
                      fill="#D53E3E"
                      fill-opacity="0.7"
                    />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="second-card">
          <Typography
            style={{
              fontWeight: 700,
              fontFamily: "Poppins",
              fontSize: "25px",
              margin: "10px",
            }}
          >
            Message History
          </Typography>
          <Divider style={{ background: "#888", margin: "10px" }}></Divider>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S. No</StyledTableCell>
                  <StyledTableCell align="center">Numbers</StyledTableCell>
                  <StyledTableCell align="center">Messages</StyledTableCell>
                  <StyledTableCell align="center">Date / Time</StyledTableCell>
                  <StyledTableCell align="center">status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.calories}</StyledTableCell>
                    <StyledTableCell align="center">{row.fat}</StyledTableCell>
                    <StyledTableCell align="center">{row.carbs}</StyledTableCell>
                    <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center" }}>
                      <p className="message-status">Completed</p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>


      </Grid>
    </Grid>
  );
};

export default Dashboard;

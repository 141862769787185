import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "../../../router/styles/drawer.css";
import "./style.css";
import { MdContacts } from "react-icons/md";
import { IoArrowRedo } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import { Link } from 'react-router-dom';
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Button, IconButton, Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Input from '@mui/joy/Input';
import Search from '@mui/icons-material/Search';
import Report from "../../../Accets/reportwp.png";
import Profile from "../../../Accets/cont.png";
import Mess from "../../../Accets/mes.png";
import Chat from "../../../Accets/chat.png";
import Expo from "../../../Accets/expo.png";
import Api from "../../../Accets/api.png";
import Contact from "../../../Accets/contasss.png";
import PROFILELOGO2 from "../../../Accets/profile logo2.png";
import PARTICIPATLOGO2 from "../../../Accets/paticipat logo2.png";
import Chat2 from "../../../Accets/chat2.png";
import robotLogo from "../../../Accets/robot image1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cardlogo from "../../../Accets/card logo1.png";
import NSWHATLOGO3 from "../../../Accets/sns logo1.png";
import CARDLOGO5 from "../../../Accets/card logo5.png";
import CARDLOGO6 from "../../../Accets/Card logo 6.png";
import { LuPlusSquare } from "react-icons/lu";
import { TfiMenuAlt } from "react-icons/tfi";
import BULKMESSAGELOGO from "../../../Accets/bulk message logo1.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BULKLOGO2 from "../../../Accets/bulk logo2.png";
import BULKLOGO3 from "../../../Accets/sns logo1.png";
import LOGO3 from "../../../Accets/card logo5 2.png";
import LOGO7 from "../../../Accets/logo7.png";
import CONTACTIMAGE1 from "../../../Accets/Contact image1.png";
import { FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { Card, Divider, Grid } from "@mui/material";

// MUI icons

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';


import { FaWhatsapp } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "transparent" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#9747FF"
        : "linear-gradient(89.97deg, #9747FF 1.43%, rgba(56, 182, 255, 0.33) 134.69%)",
  },
}));
const drawerWidth = 280;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "60px 150px 0px 10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PermanentDrawerLeft(props) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const profiles = [
    {
      id: 1,
      name: "Saswat Kumar",
      imgSrc: "https://media.licdn.com/dms/image/D4D03AQFXcs4yKfXr3g/profile-displayphoto-shrink_200_200/0/1682431817314?e=2147483647&v=beta&t=RSyjn-bJy9WkNR-cImc5M0WaGIl5yLvp6RzWXS8JU6c",
    },
    {
      id: 2,
      name: "Suman Kumar Pradhan",
      imgSrc: "https://media.licdn.com/dms/image/v2/D5603AQHkGrxPlJ6iTg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1712164662749?e=1730937600&v=beta&t=WEUoDHtDuDEXyO06o9gJaiBa8nRV86LxkFPKzZX8Ss0",
    },
    {
      id: 2,
      name: "Manas Ranjan Jena",
      imgSrc: "https://media.licdn.com/dms/image/v2/D5635AQEYLOSs-JUTWA/profile-framedphoto-shrink_800_800/profile-framedphoto-shrink_800_800/0/1723920932906?e=1726059600&v=beta&t=sxV6fSLzbPAxNhC6tPRnDlg_A9Aq-yeaYpjbXck8tL8",
    },
    {
      id: 2,
      name: "Rasmiranjan Mohanty",
      imgSrc: "https://media.licdn.com/dms/image/v2/D4D03AQE7afe8OWAyLg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1720514181701?e=1730937600&v=beta&t=HQCHN3mI5N5wAptrmGgqWWEHxuYPNmT_CJ48VmxaZ00",
    },
    {
      id: 2,
      name: "Fakir Nayak",
      imgSrc: "https://media.licdn.com/dms/image/v2/D5603AQECdvMkOiET4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690916716124?e=1730937600&v=beta&t=jnYZsUXmQzwvaXygtWqZ0tjdYlob22vR9o-EJhXeLl0",
    },
    {
      id: 2,
      name: "Simanchala Sethi",
      imgSrc: "https://media.licdn.com/dms/image/v2/D5603AQFhdxRBrhBfmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720249193804?e=1730937600&v=beta&t=OMF5WR19b6OV2RIADlcHk3FU9ixS1O_Vh-ZnmYPjnBs",
    },


  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          zIndex: "10000",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginLeft: "109px",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Typography
          style={{
            margin: "20px 0px 0px",
            textAlign: "center",
            fontWeight: 500,
            fontSize: "22px",
            color: "#07ba39",
          }}
        >
          <img src="https://cdn.iconscout.com/icon/free/png-256/free-whatsapp-business-logo-icon-download-in-svg-png-gif-file-formats--technology-brand-social-media-company-logos-pack-icons-6297350.png?f=webp&h=112" style={{ width: "28px", margin: "20px 10px -5px 0" }} />Business WhatsApp
        </Typography>
        <Typography
          style={{
            margin: "40px 0px 0px 15px",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          Features
        </Typography>

        <List className="drawer2-animation">
          {/* {sideBar( open)} */}
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              // aria-label="Vertical tabs example"
              sx={{ width: "300px" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 0 ? "#dcdcdc" : "transparent",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 0 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                icon={
                  <img
                    src={Chat2}
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                }
                label="Live Chat"
                style={{ color: "#242424" }}
                {...a11yProps(0)}
              ></Tab>


              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <GroupsRoundedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>Group WA</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <SubjectRoundedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>Template WA</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <AssessmentOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>WA Report</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <DescriptionOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>Catalog</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <SmartToyOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>Bot Auto Responders</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <SaveAsOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>Compose WA</p>
                </div>
              </Link>

              <Link to="/BusinessWhatsapp">
                <div className="bussinesswp" style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  fontSize: "15px",
                  fontWeight: "500",
                  textAlign: "center",
                  alignItems: "center",
                }}>

                  <DataObjectOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                  <p>WA API</p>
                </div>
              </Link>


            </Tabs>
          </Box>
        </List>
      </Drawer>

      <Box style={{ backgroundColor: "white" }}>
        <TabPanel value={value} index={0}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "30px 20px 20px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "left", width: "100%" }}
                >
                  Live Chat
                </Typography>
              </Grid>
              <Grid
                style={{
                  padding: "5px 20px",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 10,
                  }}>
                  <div className='seacrch-input'>
                    <Input
                      variant="soft"
                      placeholder="Search"
                      type="email"
                      name="email"
                      className='seacrch-input'
                      endDecorator={
                        <IconButton variant="soft" color="primary" aria-label="subscribe">
                          <Search />
                        </IconButton>
                      }
                      sx={{ ml: 'auto', display: { xs: 'none', md: 'flex' } }}
                    />
                  </div>
                </Grid>
                <Typography
                  style={{ fontSize: "16px", fontWeight: 700, textAlign: "left", width: "100%", marginTop: "30px" }}
                >
                  DIRECT MESSAGE
                </Typography>
                <hr></hr>
                <Grid
                className="chat-animation"
                  style={{
                    display: "flex",
                  }}>
                  <Grid
                    style={{
                      width: "300px",
                    }}>
                    {profiles.map((profile) => (
                      <div key={profile.id} className="chat-profile">
                        <img src={profile.imgSrc} alt={profile.name} />
                        <p>{profile.name}</p>
                      </div>
                    ))}
                  </Grid>
                  <Grid
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(239, 239, 239)",
                      marginTop: "-8px"
                    }}>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "50px 20px 20px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Welcome to Own WhatsApp Speed Message Sender
                </Typography>
              </Grid>



              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Phone Number or Upload .xlsx file
                    </Typography>
                    <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    <input type="file" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Message
                    </Typography>
                    <textarea style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "100px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "10px 10px", color: "white", outline: "none" }}></textarea>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Attach
                    </Typography>
                    <select style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }}>
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <input type="file" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }}>Send Message</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel style={{ backgroundColor: "black" }} value={value} index={2}>
          <Grid
            style={{ backgroundColor: "#000", height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "50px 20px 20px 20px ",
                }}
              >
                <Typography
                  style={{ color: "#fff", fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Welcome to Own WhatsApp Speed Message Sender
                </Typography>
              </Grid>



              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <iframe src="http://35.200.161.163:7001/rest/api/singleWP" style={{ height: "80vh", width: "100vw" }}></iframe>
                {/* <Card className="card-two" style={{height: "auto", backgroundImage: "linear-gradient(135deg, #0C487B, #6606AD)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Phone Number or Upload .xlsx file
                    </Typography>
                    <input type="text" style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <input type="file" style={{width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Message
                    </Typography>
                    <textarea style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "100px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "10px 10px", color: "white", outline: "none"}}></textarea>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "19px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Attach
                    </Typography>
                    <select style={{width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}}>
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <input type="file" style={{width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "calc((100% - 10px)/2)", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none"}} placeholder="Enter Number (comma separated)"/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                    <button style={{maxWidth: "200px", marginTop: "20px"}}>Send Message</button>
                    </div>
                  </Grid>
                </Card> */}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Box>
  );
}

import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "../../../router/styles/drawer.css";
import { MdContacts } from "react-icons/md";
import { IoArrowRedo } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Button, IconButton, Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Report from "../../../Accets/reportwp.png";
import Profile from "../../../Accets/cont.png";
import Mess from "../../../Accets/mes.png";
import Chat from "../../../Accets/chat.png";
import Expo from "../../../Accets/expo.png";
import Api from "../../../Accets/api.png";
import Contact from "../../../Accets/contasss.png";
import PROFILELOGO2 from "../../../Accets/profile logo2.png";
import PARTICIPATLOGO2 from "../../../Accets/paticipat logo2.png";
import robotLogo from "../../../Accets/robot image1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cardlogo from "../../../Accets/card logo1.png";
import NSWHATLOGO3 from "../../../Accets/sns logo1.png";
import CARDLOGO5 from "../../../Accets/card logo5.png";
import CARDLOGO6 from "../../../Accets/Card logo 6.png";
import { LuPlusSquare } from "react-icons/lu";
import { TfiMenuAlt } from "react-icons/tfi";
import BULKMESSAGELOGO from "../../../Accets/bulk message logo1.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BULKLOGO2 from "../../../Accets/bulk logo2.png";
import BULKLOGO3 from "../../../Accets/sns logo1.png";
import LOGO3 from "../../../Accets/card logo5 2.png";
import LOGO7 from "../../../Accets/logo7.png";
import CONTACTIMAGE1 from "../../../Accets/Contact image1.png";
import { FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { Card, Divider, Grid } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

// MUI icons


import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import Filter1OutlinedIcon from '@mui/icons-material/Filter1Outlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "transparent" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#9747FF"
        : "linear-gradient(89.97deg, #9747FF 1.43%, rgba(56, 182, 255, 0.33) 134.69%)",
  },
}));
const drawerWidth = 280;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // Function to handle file selection

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "60px 150px 0px 10px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PermanentDrawerLeft(props) {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [audioSrc, setAudioSrc] = useState(null);

  const handleAudioUpload = (event) => {
    const file = event.target.files[0];

    URL.revokeObjectURL(audioSrc);

    if (file) {
      const audioURL = URL.createObjectURL(file);
      setAudioSrc(audioURL);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: "10000",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          "&.MuiDrawer-root .MuiDrawer-paper": {
            marginLeft: "109px",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Typography
          style={{
            margin: "20px 0px 0px",
            textAlign: "center",
            fontWeight: 500,
            fontSize: "22px",
            color: "#b000ff",
          }}
        >
          <VoiceChatIcon sx={{ margin: "20px 10px -8px", fontSize: "30px" }} />Voice SMS
        </Typography>

        <Typography
          style={{
            margin: "40px 0px 0px 15px",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          Features
        </Typography>

        <List className="drawer2-animation">
          {/* {sideBar( open)} */}
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              // aria-label="Vertical tabs example"
              sx={{ width: "300px" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 0 ? "#eeeeee" : "transparent",
                  color: "#121212",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 0 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                icon={
                  <Filter1OutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                }
                label="Single Voice SMS"
                style={{ color: "#242424" }}
                {...a11yProps(0)}
              ></Tab>

              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 1 ? "#eeeeee" : "transparent",
                  color: "black",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 1 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                style={{ color: "#242424" }}
                icon={
                  <DynamicFeedOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                }
                label="Bulk Voice SMS"
                {...a11yProps(1)}
              />

              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 2 ? "#eeeeee" : "transparent",
                  color: "black",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 2 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                style={{ color: "#242424" }}
                icon={
                  <InterpreterModeOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                }
                label="Group Voice SMS"
                {...a11yProps(1)}
              />

              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "20px",
                  backgroundColor: value === 3 ? "#eeeeee" : "transparent",
                  color: "black",
                  borderRadius: "28px",
                  margin: "0px 8px 15px 15px",
                  textTransform: "capitalize",
                  // opacity: 0.7,
                  fontSize: "15px",
                  textAlign: "center",
                  minHeight: value === 3 ? "20px" : "10px", // Adjust minHeight instead of height
                  alignItems: "center", // Align content vertically
                }}
                style={{ color: "#242424" }}
                icon={
                  <DriveFolderUploadOutlinedIcon
                    style={{
                      height: "35px",
                      width: "35px",
                      marginBottom: "0px",
                      backgroundColor: "#42AAFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  />
                }
                label="Upload Voice File"
                {...a11yProps(1)}
              />

            </Tabs>
          </Box>
        </List>
      </Drawer>

      <Box style={{ backgroundColor: "white" }}>
        <TabPanel value={value} index={0}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "35px 20px 0px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Send Voice SMS
                </Typography>
              </Grid>



              <Grid
                className="box-animation"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Voice File
                    </Typography>
                    <input type="file" onChange={handleAudioUpload} accept="audio/*" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    {audioSrc && (
                      <div>
                        <audio controls style={{ width: "100%" }}>
                          <source src={audioSrc} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No of Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Interval of Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </select>
                    <Typography
                      style={{
                        color: "#e5e5e5",
                        fontWeight: 500,
                        fontSize: "15px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      Transactional voice file needs Approval before sending.
                    </Typography>
                    <textarea style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "100px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "10px 10px", color: "white", outline: "none" }} placeholder="Message Text"></textarea>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }}>Send Instant Voice SMS</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "35px 20px 0px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Send Bulk Voice SMS
                </Typography>
              </Grid>



              <Grid
                className="box-animation"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Voice File
                    </Typography>
                    <input type="file" onChange={handleAudioUpload} accept="audio/*" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    {audioSrc && (
                      <div>
                        <audio controls style={{ width: "100%" }}>
                          <source src={audioSrc} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No of Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Interval for Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </select>
                    <Typography
                      style={{
                        color: "#e5e5e5",
                        fontWeight: 500,
                        fontSize: "15px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      Transactional voice file needs Approval before sending.
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Upload CSV File
                    </Typography>
                    <input type="file" onChange={handleAudioUpload} accept="audio/*" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }}>Send Instant Voice SMS</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "35px 20px 0px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Send Voice SMS to Group
                </Typography>
              </Grid>



              <Grid
                className="box-animation"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Voice File
                    </Typography>
                    <input type="file" onChange={handleAudioUpload} accept="audio/*" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    {audioSrc && (
                      <div>
                        <audio controls style={{ width: "100%" }}>
                          <source src={audioSrc} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No of Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>image</option>
                      <option>video</option>
                      <option>document</option>
                    </select>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Interval for Retries
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </select>
                    <Typography
                      style={{
                        color: "#e5e5e5",
                        fontWeight: 500,
                        fontSize: "15px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      Transactional voice file needs Approval before sending.
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Select Group
                    </Typography>
                    <select className="inputstyle2">
                      <option>Select ...</option>
                      <option>option1</option>
                      <option>option2</option>
                      <option>option3</option>
                    </select>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }}>Send Instant Voice SMS</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid
            style={{ height: "100vh", width: "70vw" }}
          >
            <Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  padding: "35px 20px 0px 20px ",
                }}
              >
                <Typography
                  style={{ fontSize: "25px", fontWeight: 700, textAlign: "center", width: "100%" }}
                >
                  Upload Voice File
                </Typography>
              </Grid>



              <Grid
                className="box-animation"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "3%",
                  margin: "15px",
                }}
              >
                <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, #72b1e9, #bd87d5)", borderRadius: "30px" }}>
                  <Grid
                    style={{
                      padding: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Name
                    </Typography>
                    <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />

                    <Typography
                      style={{
                        color: "white",
                        fontWeight: 500,
                        fontSize: "17px",
                        marginTop: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Voice File
                    </Typography>
                    <input type="file" onChange={handleAudioUpload} accept="audio/*" style={{ width: "100%", backgroundColor: "transparent", border: "none", borderRadius: "30px", height: "40px", width: "100%", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} placeholder="Enter Number (comma separated)" />
                    {audioSrc && (
                      <div>
                        <audio controls style={{ width: "100%" }}>
                          <source src={audioSrc} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}
                    
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button style={{ maxWidth: "200px", marginTop: "20px" }}>Upload Voice File</button>
                    </div>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div id="PATICIPATE_PAGE_MAIN_SECTION">
            <div id="PATICIPATE_PAGE_TOP_SECTION">
              <IoArrowRedo id="PATICIPATE_PAGE_ICON_ONE" />
              <h2 id="PATICIPATE_PAGE_HEADING_ONE">Export participants</h2>
            </div>
            <div id="PATICIPATE_PAGE_PARAGRAPH_ONE">
              <p id="PATICIPATE_PAGE_PARAGRAPH_ONE_UP">
                Export participant list
              </p>
            </div>
            {/*  */}
            <div id="PATICIPATE_PAGE_DROPDOWN">
              <select name="" id="PATICIPATE_PAGE_select" selected>
                <FaWhatsapp id="PATICIPATE_PAGE-ICON" />
                <option value="" id="PATICIPATE_PAGE_option" selected>
                  <FaWhatsapp id="PATICIPATE_PAGE-ICON" />
                  Select Whatsapp Accounts
                </option>
                <option value=""></option>
              </select>
            </div>
            {/*  */}
            <div id="PATICIPATE_PAGE_CARD_MAIN_BODY">
              <div id="PATICIPATE_PAGE_CARD_TOP">
                <p id="PATICIPATE_PAGE_CARD_TOP_PARAGRAPH_ONE">How To Use?</p>
                <hr id="PATICIPATE_PAGE_HR_ONE" />
                <p id="PATICIPATE_PAGE_CARD_TOP_PARAGRAPH_ONE">
                  1. Send a message to group you want export participants
                </p>
                <hr id="PATICIPATE_PAGE_HR_ONE" />
                <p id="PATICIPATE_PAGE_CARD_TOP_PARAGRAPH_ONE">
                  2. Select account you want export participants
                </p>
                <hr id="PATICIPATE_PAGE_HR_ONE" />
                <p id="PATICIPATE_PAGE_CARD_TOP_PARAGRAPH_ONE">
                  3. Click Download button of group you want export on list
                </p>
              </div>
            </div>
            {/* image section */}
            <div id="PATICIPATE_PAGE_CARD_IMAGE">
              <img
                src={PARTICIPATLOGO2}
                alt=""
                id="PATICIPATE_PAGE_CARD_IMAGE_FLEX"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div id="NCONTACTPAGE_MAIN_BODY">
            <div id="NCONTACTPAGE_TOP_BODY">
              <MdContacts id="NCONTACTPAGE_ICON-ONE" />
              <h2 id="NCONTACTPAGE_HEADING_ONE">Contacts</h2>
            </div>
            <div id="NCONTACTPAGE_PARAGRAPH_DIV">
              <p id="NCONTACTPAGE_PARAGRAPH_ONE">Create, edit your contacts</p>
              <div id="">
                <div id="NCONTACTPAGE_INPUT_BOX">
                  <div>
                    <Paper
                      component="form"
                      style={{
                        width: "260px",
                        height: "2.5rem",
                        borderRadius: "0.625rem",
                        border: "1px solid  black",
                        background: "#FFF",
                        boxShadow: "0px 0px 10px 0px rgba(163, 163, 163, 0.25)",
                        margin: "13px",
                      }}
                    >
                      <IconButton
                        type="button"
                        style={{ p: "150px" }}
                        aria-label="search"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21.7555 20.6065L18.3182 17.2458L18.2376 17.1233C18.0878 16.9742 17.883 16.8902 17.6692 16.8902C17.4554 16.8902 17.2505 16.9742 17.1007 17.1233C14.1795 19.8033 9.67815 19.949 6.58201 17.4637C3.48586 14.9784 2.75567 10.6334 4.87568 7.31017C6.9957 3.98697 11.3081 2.71685 14.9528 4.34214C18.5976 5.96743 20.4438 9.98379 19.267 13.7276C19.1823 13.9981 19.2515 14.2922 19.4487 14.4992C19.6459 14.7062 19.9411 14.7946 20.223 14.7311C20.505 14.6676 20.7309 14.4619 20.8156 14.1914C22.2224 9.74864 20.0977 4.96755 15.8161 2.94106C11.5345 0.914562 6.38084 2.25082 3.68905 6.08542C0.99727 9.92001 1.57518 15.1021 5.04893 18.2795C8.52268 21.4569 13.8498 21.6759 17.5841 18.7949L20.6277 21.7705C20.942 22.0765 21.4502 22.0765 21.7645 21.7705C22.0785 21.4602 22.0785 20.9606 21.7645 20.6503L21.7555 20.6065Z"
                            fill="#121212"
                            fill-opacity="0.7"
                          />
                        </svg>
                      </IconButton>
                      <InputBase
                        style={{ ml: 5, flex: 1, fontSize: "15px" }}
                        placeholder="Search "
                        inputProps={{ "aria-label": "Search " }}
                        onChange={(e) => {
                          // this.props.search_course(courses.course, e.target.value, "search")
                          // console.log("df", this.props.courses.search_course.length)
                        }}
                      />
                    </Paper>
                  </div>
                </div>
              </div>
              <div id="NCONTACTPAGE_BUTTON-BODY">
                <button id="NCONTACTPAGE_BUTTON_UP">+</button>
              </div>
            </div>
            {/*  */}
            <div id="NCONTACTPAGE_CARD_MAIN_BODY">
              <div id="NCONTACTPAGE_CARD_ALL">
                <div id="NCONTACTPAGE_LEFT_BODY">
                  <p id="NCONTACTPAGE_CARD_PARAGRAPH_ONE">Test</p>
                  <p id="NCONTACTPAGE_CARD_PARAGRAPH_TWO">498 contacts</p>
                  <div id="NCONTACTPAGE_CARD_MENU_LIST_ITEM">
                    <div id="NCONTACTPAGE_CARD_UNDER_ICONS_UP_BODY">
                      <LuPenSquare id="NCONTACTPAGE_CARD_UNDER_ICONS" />
                    </div>
                    <div id="NCONTACTPAGE_CARD_UNDER_ICONS_UP_BODY">
                      <TfiMenuAlt id="NCONTACTPAGE_CARD_UNDER_ICONS" />
                    </div>
                    <div id="NCONTACTPAGE_CARD_UNDER_ICONS_UP_BODY">
                      <RiDeleteBin6Line id="NCONTACTPAGE_CARD_UNDER_ICONS" />
                    </div>
                  </div>
                </div>
                {/*  right body*/}
                <div id="NCONTACTPAGE_RIGHT_BODY">
                  <img
                    src={CONTACTIMAGE1}
                    alt=""
                    id="NCONTACTPAGE_RIGHT_BODY_IMAGE"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Box>
    </Box>
  );
}

import { GET_ALL_CONTACT } from "./constant";

const initial_state = {
  alldelatilscontact: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case GET_ALL_CONTACT:
      return (state = {
        ...state,
        alldelatilscontact: action.payload.data,
        count: action.payload.count,
      });
    default:
      return state;
  }
}

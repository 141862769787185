import { setLoader, unsetLoader } from "../../common/loader/action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../../common/snackbar/action";
import "firebase/storage";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import { GET_ALL_USER } from "./constant";




export function ViewallUsers() {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.ADMINUSERURL + "/view-alluser", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("asiillllllllllllla", responseJson.result.data);
        if (responseJson.status) {
          dispatch({
            type: GET_ALL_USER,
            payload: {
              data: responseJson.result.data.result,
              count: responseJson.result.data.length,
            },
          });
          dispatch(set_snack_bar(true, "Data View Sucessfully"));
          dispatch(unsetLoader());
        } else {
          dispatch({
            type: GET_ALL_USER,
            payload: { data: [], count: 0 },
          });
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function AdmincreateUser(name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall) {
  console.log(name, orgname, email, number, password)
  return async (dispatch) => {
    dispatch(setLoader());
    try {
      const response = await fetch(UNIVERSAL.ADMINUSERURL + "create-user", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          orgname: orgname,
          email: email,
          number: number,
          password: password,
          whatsapp: whatsapp,
          businesswhatsapp: businesswhatsapp,
          sms: sms,
          voicecall: voicecall,
        }),
      });
      const responseJson = await response.json();
      console.log("asilllllll", responseJson.displayMessage);
      if (responseJson.status) {
        dispatch(set_snack_bar(responseJson.status, responseJson.displayMessage));
        dispatch(ViewallUsers())
      } else {
        dispatch(set_snack_bar(responseJson.status, responseJson.displayMessage));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(unsetLoader());
    }
  };
}

export function Delete_all_users(id) {
  console.log(id)
  return (dispatch) => {
    console.log(id)
    dispatch(setLoader());
    return fetch(UNIVERSAL.ADMINUSERURL + "delete-user/" + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(ViewallUsers(id));

          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function Updateallusers(id, name, orgname, email,password, number, whatsapp, businesswhatsapp, sms, voicecall) {
  console.log(id, name, orgname, email,password, number, whatsapp, businesswhatsapp, sms, voicecall)
  return (dispatch) => {

    console.log(id, name, orgname, email,password, number, whatsapp, businesswhatsapp, sms, voicecall)
    dispatch(setLoader());
    return fetch(UNIVERSAL.ADMINUSERURL + "update-user/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: token
      },
      body: JSON.stringify({
        name: name,
        orgname: orgname,
        email: email,
        password:password,
        number: number,
        whatsapp: whatsapp,
        businesswhatsapp: businesswhatsapp,
        sms: sms,
        voicecall: voicecall,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          dispatch(ViewallUsers());
          dispatch(unsetLoader());
        } else {

        }
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.MuiTableCell-root {
    max-width: 200px; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
   */

  `, "",{"version":3,"sources":["webpack://./src/pages/ContactusDetails/Component/ContactDetails.css"],"names":[],"mappings":"AAAA;;;;;;;IAOI","sourcesContent":["/* \n.MuiTableCell-root {\n    max-width: 200px; \n    white-space: nowrap; \n    overflow: hidden; \n    text-overflow: ellipsis;\n  }\n   */\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

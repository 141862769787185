import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { AdmincreateUser, Delete_all_users, Updateallusers, ViewallUsers } from "../action.js";
import PermanentDrawerLeft from "../Components/AdminWhatsApp.js";
export class Controller extends Component {
  render() {
    return <PermanentDrawerLeft {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    allusers: store.allusers,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    ViewallUsers: () => {
      dispatch(ViewallUsers());
    },
    AdmincreateUser: (name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall) => {
      dispatch(AdmincreateUser(name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall))
    },
    Delete_all_users: (id) => {
      dispatch(Delete_all_users(id))
    },
    Updateallusers: (id, name, orgname, email,password, number, whatsapp, businesswhatsapp, sms, voicecall) => {
      dispatch(Updateallusers(id, name, orgname, email,password, number, whatsapp, businesswhatsapp, sms, voicecall))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);

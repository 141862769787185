import React, { useState, useEffect } from "react";
import "../../../pages/ContactUs/Styles/style.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "../../../router/styles/drawer.css";
import { MdContacts } from "react-icons/md";
import { IoArrowRedo } from "react-icons/io5";
import { LuPenSquare } from "react-icons/lu";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Button, IconButton, Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Report from "../../../Accets/reportwp.png";
import Profile from "../../../Accets/cont.png";
import Contactpic from "../../../Accets/contactus.png";
import Chat from "../../../Accets/chat.png";
import Expo from "../../../Accets/expo.png";
import Api from "../../../Accets/api.png";
import Contact from "../../../Accets/contasss.png";
import PROFILELOGO2 from "../../../Accets/profile logo2.png";
import PARTICIPATLOGO2 from "../../../Accets/paticipat logo2.png";
import robotLogo from "../../../Accets/robot image1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cardlogo from "../../../Accets/card logo1.png";
import NSWHATLOGO3 from "../../../Accets/sns logo1.png";
import CARDLOGO5 from "../../../Accets/card logo5.png";
import CARDLOGO6 from "../../../Accets/Card logo 6.png";
import { LuPlusSquare } from "react-icons/lu";
import TextField from '@mui/material/TextField';
import { TfiMenuAlt } from "react-icons/tfi";
import SearchIcon from '@mui/icons-material/Search';
import BULKMESSAGELOGO from "../../../Accets/bulk message logo1.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BULKLOGO2 from "../../../Accets/bulk logo2.png";
import BULKLOGO3 from "../../../Accets/sns logo1.png";
import LOGO3 from "../../../Accets/card logo5 2.png";
import LOGO7 from "../../../Accets/logo7.png";
import CONTACTIMAGE1 from "../../../Accets/Contact image1.png";
import { FaDeleteLeft, FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiBarChartLine } from "react-icons/ri";
import { Card, Divider, Grid } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MessageIcon from '@mui/icons-material/Message';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import { FaWhatsapp } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

// icons mui

import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// components mui

import Input from '@mui/joy/Input';
import Search from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";


// table mui

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../Components/AdminWhatsapp.css';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "transparent" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? "#9747FF"
        : "linear-gradient(89.97deg, #9747FF 1.43%, rgba(56, 182, 255, 0.33) 134.69%)",
  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function PermanentDrawerLeft(props) {

  const [value, setValue] = React.useState(0);
  // const [open, setOpen] = React.useState(false);
  const [modalopen, modalsetOpen] = React.useState(false);
  const [edituseropen, setedituseropen] = useState(false);

  const toggleueseropen = () => {
    setedituseropen(true);
  }
  const toggleueserclose = () => {
    setedituseropen(false);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    modalsetOpen(true);
  };

  const handleClose = () => {
    modalsetOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);


  const handleViewClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };


  const handleClose2 = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    props.ViewallUsers(props.login.admin_id);
  }, []);
  const [credit, setcredit] = useState("");
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [orgname, setorgname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [password, setpassword] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [businesswhatsapp, setbusinesswhatsapp] = useState("");
  const [sms, setsms] = useState("");
  const [voicecall, setvoicecall] = useState("");

  const [editopen, seteditOpen] = React.useState(false);
  const handleOpenedit = () => {
    seteditOpen(true)
  };
  const handleCloseedit = () => seteditOpen(false);
  const handledeleteuser = () => {
    props.Delete_all_users(id, props.login.admin_id)
  }

  const [deleteopen, setdeleteopen] = React.useState(false);
  const handleClickdeleteOpen = () => {
    setdeleteopen(true);
  };

  const handledeleteClose = () => {
    setdeleteopen(false);
    props.Delete_all_users(id)
  };
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setUserIdToDelete(id);
    setdeleteopen(true);
  };

  const handleConfirmDelete = () => {
    if (userIdToDelete) {
      props.Delete_all_users(userIdToDelete);
    }
    setdeleteopen(false);
  };
  const [searchTerm, setSearchTerm] = useState('');


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const filteredUsers = Array.isArray(props.allusers.all_users)
    ? props.allusers.all_users.filter((user) =>
      user._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];
  // validation
  // const [errors, setErrors] = useState({
  //   name: "",
  //   orgname: "",
  //   email: "",
  //   number: "",
  //   password: ""
  // });

  // const validateInputs = () => {
  //   let valid = true;
  //   let newErrors = {};

  //   if (!name) {
  //     newErrors.name = "Name is required";
  //     valid = false;
  //   }

  //   if (!orgname) {
  //     newErrors.orgname = "Organization name is required";
  //     valid = false;
  //   }

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(email)) {
  //     newErrors.email = "Invalid email format";
  //     valid = false;
  //   }

  //   const mobileRegex = /^[0-9]{10}$/;
  //   if (!mobileRegex.test(number)) {
  //     newErrors.number = "Mobile number must be exactly 10 digits";
  //     valid = false;
  //   }

  //   const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  //   if (!passwordRegex.test(password)) {
  //     newErrors.password = "Password must be at least 8 characters long and contain letters and numbers";
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };

  // const handleSubmit = () => {
  //   console.log(name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall)
  //   if (validateInputs()) {
  //     props.AdmincreateUser(name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall);
  //     modalsetOpen(false);
  //   }
  // };
  return (
    <>
      <div >

        <Grid
          style={{
            height: "100vh",
            width: "calc(100vw - 180px)",
            margin: "30px 0 0 140px",
          }}
        >
          <Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                padding: "30px 20px 20px 20px ",
              }}
            >
              <Typography
                style={{ fontSize: "25px", fontWeight: 700, textAlign: "left", width: "100%" }}
              >
                Manage Users
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 10,
                padding: "20px 20px 10px 20px ",
              }}>
              <div className='search-input'>
                <Input
                  variant="soft"
                  placeholder="Search...."
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  endDecorator={
                    <IconButton variant="soft" color="primary" aria-label="search">
                      <Search />
                    </IconButton>
                  }
                  sx={{ ml: 'auto', display: { xs: 'none', md: 'flex' } }}
                />
              </div>
              <div className='seacrch-input'>
                <Button variant="contained" style={{ backgroundColor: "#42aaff" }} onClick={handleClickOpen}>
                  Add New User
                </Button>
                <Dialog
                  open={modalopen}
                  // TransitionComponent={Transition}
                  style={{ zIndex: "10000" }}
                  keepMounted
                  // onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"

                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: "3%",
                      // margin: "15px",
                    }}
                  >
                    <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, rgba(114, 177, 233, 0.5), rgba(189, 135, 213, 0.5))", borderRadius: "30px", margin: "0" }}>
                      <Grid style={{ padding: "30px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography style={{ color: "white", fontWeight: 700, fontSize: "22px", marginBottom: "20px", whiteSpace: "nowrap" }}>Add New User</Typography>
                          <Button onClick={handleClose} style={{ height: "38px", color: "white" }}><CloseIcon /></Button>
                        </div>


                        <div>
                          <Typography style={{ color: "white", fontWeight: 500, fontSize: "17px", marginTop: "10px", whiteSpace: "nowrap" }}>Name</Typography>
                          <input type="text" className="name_admin" value={name} onChange={(e) => setname(e.target.value)} />
                        </div>
                        <div>
                          <Typography style={{ color: "white", fontWeight: 500, fontSize: "17px", marginTop: "10px", whiteSpace: "nowrap" }}>Org-Name</Typography>
                          <input type="text" className="orgname-input" value={orgname} onChange={(e) => setorgname(e.target.value)} />
                        </div>
                        <div>
                          <Typography style={{ color: "white", fontWeight: 500, fontSize: "17px", marginTop: "10px", whiteSpace: "nowrap" }}>Email</Typography>
                          <input
                            type="email"
                            className="email-admin"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />

                        </div>

                        <div>
                          <Typography style={{ color: "white", fontWeight: 500, fontSize: "17px", marginTop: "10px", whiteSpace: "nowrap" }}>Mobile</Typography>
                          <input
                            type="text"
                            className="mobile_admin"
                            value={number}
                            required
                            onChange={(e) => setnumber(e.target.value)}
                          />
                        </div>
                        <div>
                          <Typography style={{ color: "white", fontWeight: 500, fontSize: "17px", marginTop: "10px", whiteSpace: "nowrap" }}>Password</Typography>
                          <input
                            type="password"
                            className="mobile_admin"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </div>
                        <div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "30px", marginTop: "10px" }}>
                              <div>
                                <Typography style={{ color: "#fff" }}>Whatsapp</Typography>
                                <input type="text" className="mobile_admin-wp" value={whatsapp} onChange={(e) => setwhatsapp(e.target.value)} />
                              </div>
                              <div>
                                <Typography style={{ color: "#fff" }}>Business WP</Typography>
                                <input type="text" className="mobile_admin-wp" value={businesswhatsapp} onChange={(e) => setbusinesswhatsapp(e.target.value)} />
                              </div>
                            </div>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "30px", marginTop: "10px" }}>
                              <div>
                                <Typography style={{ color: "#fff" }}>SMS</Typography>
                                <input type="text" className="mobile_admin-wp" value={sms} onChange={(e) => setsms(e.target.value)} />
                              </div>
                              <div>
                                <Typography style={{ color: "#fff" }}>Voice Call</Typography>
                                <input type="text" className="mobile_admin-wp" value={voicecall} onChange={(e) => setvoicecall(e.target.value)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <button
                            style={{ maxWidth: "200px", marginTop: "20px", padding: "7px 30px", backgroundColor: "#42aaff" }}
                            onClick={() => {
                              props.AdmincreateUser(name, orgname, email, number, password, whatsapp, businesswhatsapp, sms, voicecall);
                              modalsetOpen(false);
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </Grid>
                    </Card>
                  </Grid>
                </Dialog>
              </div>
            </Grid>
            <Grid
              style={{
                padding: "20px",
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S. No</StyledTableCell>
                      <StyledTableCell align="center">Org-Name</StyledTableCell>
                      <StyledTableCell align="center">Email</StyledTableCell>
                      <StyledTableCell align="center">Password</StyledTableCell>
                      <StyledTableCell align="center">Whatsapp</StyledTableCell>
                      <StyledTableCell align="center">BusinessWhatsapp</StyledTableCell>
                      <StyledTableCell align="center">Sms</StyledTableCell>
                      <StyledTableCell align="center">VoiceCall</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.orgname}</StyledTableCell>
                        <StyledTableCell align="center">{row.email}</StyledTableCell>
                        <StyledTableCell align="center">{row.password}</StyledTableCell>
                        <StyledTableCell align="center">{row.whatsapp}</StyledTableCell>
                        <StyledTableCell align="center">{row.businesswhatsapp}</StyledTableCell>
                        <StyledTableCell align="center">{row.sms}</StyledTableCell>
                        <StyledTableCell align="center">{row.voicecall}</StyledTableCell>
                        <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center" }}>
                          <Button onClick={() => {
                            setedituseropen(!edituseropen);
                            setname(row.name);
                            setorgname(row.orgname);
                            setemail(row.email);
                            setnumber(row.number);
                            setcredit(row.credit);
                            setpassword(row.password);
                            setwhatsapp(row.whatsapp);
                            setbusinesswhatsapp(row.businesswhatsapp);
                            setsms(row.sms); 
                            setvoicecall(row.voicecall);
                            setId(row._id);
                          }}>
                            <FiEdit fontSize={20} color="#19B300" />
                          </Button>
                          <Button style={{ color: "#2490ca", fontSize: "10px", padding: "3px 10px", margin: "10px 0" }} onClick={() => handleViewClick(row)}>
                            <FaEye fontSize={22} />
                          </Button>
                          <Button onClick={() => handleDeleteClick(row._id)}>
                            <MdDelete fontSize={20} />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog open={open} onClose={handleClose}>
                <div style={{ backgroundImage: "linear-gradient(135deg, #3a81bfc4 , #646869b6 )", padding: "20px", color: "white", width: "500px" }}>
                  {/* <DialogTitle style={{ textAlign: "center" }}>{selectedRow ? `${selectedRow.name}` : "Details"}</DialogTitle> */}
                  <DialogContent>
                    {selectedRow && (
                      <Grid>
                        <Table>
                          <tr>
                            <td>ID</td>
                            <td>: {selectedRow._id}</td>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>: {selectedRow.name}</td>
                          </tr>
                          <tr>
                            <td>orgname</td>
                            <td>: {selectedRow.orgname}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>: {selectedRow.email}</td>
                          </tr>
                          <tr>
                            <td>Mobile</td>
                            <td>: {selectedRow.number}</td>
                          </tr>
                          {/* <tr>
                            <td>Password</td>
                            <td>: {selectedRow.password}</td>
                          </tr> */}
                          <tr>
                            <td>Credit</td>
                            <td>: {selectedRow.credit}</td>
                          </tr>
                        </Table>
                        {/* <button style={{ backgroundColor: "rgba(0, 0, 0, 0.267)", color: "white", borderRadius: "50%", width: "40px !important", height: "30px", padding: "3px", margin: "10px 10px 0 0" }} onClick={() => {
                          setedituseropen(!edituseropen);
                          setname(selectedRow.name)
                          setorgname(selectedRow.orgname);
                          setemail(selectedRow.email);
                          setnumber(selectedRow.number);
                          setcredit(selectedRow.credit);
                          setId(selectedRow._id);
                        }} >
                          <EditNoteIcon />
                        </button> */}
                        {/* <button onClick={() => {
                          props.Delete_all_users(selectedRow._id)
                          setId(selectedRow._id)
                        }} style={{ backgroundColor: "rgba(0, 0, 0, 0.267)", color: "white", borderRadius: "50%", width: "40px !important", height: "30px", padding: "3px" }}>
                          <DeleteOutlineIcon />
                        </button> */}
                        <div>
                          {/* <Typography
                          style={{
                            color: "white",
                            fontWeight: 500,
                            fontSize: "14px",
                            margin: "5px 0 -7px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Service
                        </Typography> */}
                          {/* <select className="inputstyle2">
                          <option>Select ...</option>
                          <option>image</option>
                          <option>video</option>
                          <option>document</option>
                        </select> */}
                        </div>
                        {/* <div>
                          <Typography
                            style={{
                              color: "white",
                              fontWeight: 500,
                              fontSize: "14px",
                              margin: "5px 0 -7px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Credit ID
                          </Typography>
                          <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />
                        </div> */}
                        {/* <div>
                          <Typography
                            style={{
                              color: "white",
                              fontWeight: 500,
                              fontSize: "14px",
                              margin: "5px 0 -7px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Credit
                          </Typography>
                          <input type="text" style={{ width: "100%", backgroundColor: "transparent", border: "1px solid white", borderRadius: "30px", height: "40px", fontSize: "18px", margin: "10px 0px", padding: "0px 10px", color: "white", outline: "none" }} />
                        </div> */}
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose2} style={{ backgroundColor: "white", color: "black" }}>
                      Close
                    </Button>
                    {/* <Button onClick={handleClose2} style={{ backgroundColor: "royalblue", color: "white" }}>
                      Submit
                    </Button> */}
                  </DialogActions>
                </div>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* delete dialog */}
      <Dialog
        open={deleteopen}
        onClose={() => {
          setdeleteopen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ borderRadius: "10px" }}
      >
        <Grid style={{ backgroundColor: "#fff", borderRadius: "10px" }}>
          <DialogTitle id="alert-dialog-title" style={{ color: "#000" }}>
            are you want to delete delete or not
          </DialogTitle>
          <DialogContent>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setdeleteopen(false)
            }}>No</Button>
            {/* <Button onClick={() => {
            setdeleteopen(false);
            props.Delete_all_users(row._id)
          }} autoFocus>
            Yes
          </Button> */}
            <Button onClick={handleConfirmDelete} >yes</Button>
          </DialogActions>
        </Grid>
      </Dialog>
      {/* edit form */}
      <Dialog
        open={edituseropen}
        // TransitionComponent={Transition}
        style={{ zIndex: "10000" }}
        keepMounted
        onClose={toggleueserclose}
        aria-describedby="alert-dialog-slide-description"
        z
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "3%",
          }}
        >
          <Card className="card-two" style={{ height: "auto", backgroundImage: "linear-gradient(135deg, rgba(114, 177, 233, 0.5), rgba(189, 135, 213, 0.5))", borderRadius: "30px", margin: "0" }}>
            <Grid
              style={{
                padding: "30px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: "22px",
                    marginBottom: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Edit New User
                </Typography>
                <Button onClick={toggleueserclose} style={{ height: "38px", color: "white" }}><CloseIcon /></Button>
              </div>
              <div>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "17px",
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Name
                </Typography>
                <input type="text" className="name_admin" value={name} onChange={(e) => setname(e.target.value)} />
              </div>
              <div>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "17px",
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Org-Name
                </Typography>
                <input type="text" className="orgname-input" value={orgname} onChange={(e) => setorgname(e.target.value)} />
              </div>
              <div>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "17px",
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Email
                </Typography>
                <input type="email" className="email-admin" value={email} onChange={(e) => setemail(e.target.value)} />
              </div>
              <div>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "17px",
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Mobile
                </Typography>
                <input type="text" className="mobile_admin" value={number} onChange={(e) => setnumber(e.target.value)} />
              </div>
              <div>
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "17px",
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Password
                </Typography>
                <input type="text" className="mobile_admin" value={password} onChange={(e) => setpassword(e.target.value)} />
              </div>
              <div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "30px", marginTop: "10px" }}>
                    <div>
                      <Typography style={{ color: "#fff" }}>Whatsapp</Typography>
                      <input type="text" className="mobile_admin-wp" value={whatsapp} onChange={(e) => setwhatsapp(e.target.value)} />
                    </div>
                    <div>
                      <Typography style={{ color: "#fff" }}>Business WP</Typography>
                      <input type="text" className="mobile_admin-wp" value={businesswhatsapp} onChange={(e) => setbusinesswhatsapp(e.target.value)} />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "30px", marginTop: "10px" }}>
                    <div>
                      <Typography style={{ color: "#fff" }}>SMS</Typography>
                      <input type="text" className="mobile_admin-wp" value={sms} onChange={(e) => setsms(e.target.value)} />
                    </div>
                    <div>
                      <Typography style={{ color: "#fff" }}>Voice Call</Typography>
                      <input type="text" className="mobile_admin-wp" value={voicecall} onChange={(e) => setvoicecall(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button style={{ maxWidth: "200px", marginTop: "20px", padding: "7px 30px", backgroundColor: "#42aaff" }} onClick={() => {
                  props.Updateallusers(id, name, orgname, email, password, number, whatsapp, businesswhatsapp, sms, voicecall);
                  setedituseropen(false);
                }} >Save Edit</button>
              </div>
            </Grid>
          </Card>
        </Grid>
      </Dialog>
      <LoaderCon />
      <Snackbar
        open={props.snackbar.response_received}
        close_snack_bar={props.close_snack_bar}
        message={props.snackbar.message}
      />
    </>
  );
}



import React, { Component } from "react";
import { connect } from "react-redux";
import { close_snack_bar } from "../../../common/snackbar/action";
import { ViewallContact } from "../action.js";
import ContactDetails from "../Component/ContactDetails.js";
export class Controller extends Component {
    render() {
        return <ContactDetails {...this.props} />;
    }
}
export const mapStateToProps = (store) => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        detailscontact: store.detailscontact,
    };
};
export const mapDispatchToProps = (dispatch) => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        ViewallContact: (token) => {
            dispatch(ViewallContact(token));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);

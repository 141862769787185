// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Campaign-main-div {
  margin-left: 30px;
  margin-top: 80px;
  margin-right: 30px !important;
}
.wp-link-button {
  background-color: #25d366;
  border-radius: 10px;
  color: white;
  padding: 10px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Campaign Details/Components/CampaignDetails.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,6BAA6B;AAC/B;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".Campaign-main-div {\n  margin-left: 30px;\n  margin-top: 80px;\n  margin-right: 30px !important;\n}\n.wp-link-button {\n  background-color: #25d366;\n  border-radius: 10px;\n  color: white;\n  padding: 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const UNIVERSAL = {
  BASEURL: "http://35.200.161.163:7004/rest/api/",
  USERBASEURL: "http://34.93.117.25:4001/rest/api/",
  ADMINBASEURL: "http://34.131.45.152:32806/rest/api/",
  ADMINUSERURL: "http://35.200.161.163:7001/rest/api/",
  USERSERVICE: "http://35.200.161.163:7002/rest/api/",
};


export default UNIVERSAL;
